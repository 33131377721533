//将本地图片更换成https:路径
const imgServer2Static = (str) => {
    return (str || "").replaceAll('http://data.xiaoou.cc/uploads/', 'https://www.xiaoou.cc/uploads/medium_')
}

// 'type: large,medium,small,thumbnail'
// "type: 1000,750,500,235"
const getUrlPic = (item, type) => {
    if (!item || !item.attributes) {
        return "";
    }
    type = type || "medium"
    let pre = "";
    const width = item.attributes.width;
    if (type == "thumbnail" && width > 235) {
        pre = "thumbnail_";
    }
    if (type == "small" && width > 500) {
        pre = "small_";
    }
    if (type == "medium" && width > 750) {
        pre = "medium_"
    }
    if (type == "large" && width > 1000) {
        pre == "large_";
    }
    return useRuntimeConfig().public.imgPre + '/uploads/' + pre + item.attributes.hash + item.attributes.ext;
}
const getUrlVideo = (video) => {
    //格式化后地址：//player.bilibili.com/player.html?isOutside=true&bvid=BV1mr421379Q&p=1
    //替换vid
    //原地址：https://www.bilibili.com/video/BV14J4m1H7nn/?share_source=copy_web
    let newVideo = video || "";
    const regex = /bilibili\.com\/video\/([^\/]+)/;
    const match = video.match(regex);
    if (match && match[1]) {
        console.log('匹配到的内容:', match[1]); // 输出: BV1xH4y137ZE
        newVideo = '//player.bilibili.com/player.html?isOutside=true&bvid='+match[1]+'&p=1&autoplay=0';
    }
    return newVideo;
}
const resestHttp = (str) =>{
    str = (str || "").replaceAll('http://data.xiaojxj.com', 'https://www.xiaoou.cc');
    return str;
}
const formatDate = (str) =>{
    return (str || "").split('T')[0]
}
const formatSlug = (id,name) =>{
    //将非中英文字符串换成-
    return id +'-'+ (name || "").replace(/[^\u4e00-\u9fa5a-zA-Z]/g,'-');
}
const formatPrice = (str) =>{
    let nPrice = [];
    let nStr = (str || "").split('\n');
    nStr.forEach(element => {
        let nE = element.split('\t');
        if(nE.length == 2){
            nPrice.push({
                weeks : nE[0].replace(/\s/g,''),
                price : nE[1].replace(/\s/g,'')
            })
        }
    });
    return nPrice;
}

//数字取1位小数，为0是显示整数
const fixPrice = (str)=>{
    let num = +str;
    let result = num.toFixed(1);
    return parseFloat(result) === Math.floor(parseFloat(result)) ? parseInt(result) : result;
}
export const myUtils = {
    imgServer2Static,
    getUrlPic,
    getUrlVideo,
    resestHttp,
    formatDate,
    formatSlug,
    formatPrice,
    fixPrice
};
